@import '../../../../styles/customMediaQueries.css';

.searchItemsContainer {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: solid 1px var(--colorGrey200);
  padding: 0px;

  @media (--viewportLarge) {
  }

  & .keywordSearch {
    width: calc(100% - 30px);
    align-items: stretch;
    height: 100%;

    & .field {
      height: 100%;
      & > input {
        border: none;
        border-radius: 0;
        height: 100%;
        min-height: 50px;
        padding: 0px 15px;
        background-color: transparent;
        outline: none;
        box-shadow: none;
      }
    }
  }

  & .button {
    margin: 0 10px;
    border: none;
    transition: all ease 0.5s;
    border-radius: 110px;
    min-width: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      /* align-items: center; */
      border: none;
      border-radius: 110px;
      /* display: flex; */
      /* height: 30px; */
      justify-content: center;
      margin: 0 16px;
      /* min-width: 30px; */
      transition: all 0.5s ease;
      width: 30px;
    }

    &:hover {
      cursor: pointer;
    }

    & > svg {
      width: 24px;
      & path,
      & circle {
        fill: white;
        stroke: var(--marketplaceColor);
        stroke-width: 3px;
      }
    }
  }
}

.errorPageSearch {
  & .searchItemsContainer {
    border: none;
    height: auto;
    padding: 0;
    border-radius: 0;
    align-items: initial;
    flex-direction: column;

    @media (--viewportSmall) {
      flex-direction: row;
    }

    & .keywordSearch {
      border: solid 1px var(--colorGrey50);
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      order: 1;
      width: 100%;
      margin-right: 0px;
      margin-bottom: 10px;

      @media (--viewportSmall) {
        margin-right: 10px;
        margin-bottom: 0px;
        width: calc(100% - 60px);
      }

      & > svg {
        & circle,
        & path {
          stroke: var(--colorGrey100);
        }
      }

      & .field {
        width: calc(100% - 20px);

        & > input {
          padding: 10px 15px;
        }
      }
    }

    & .button {
      width: auto;
      height: auto;
      font-weight: 600;
      border-radius: 6px;
      padding: 10px 20px;
      order: 2;
      color: var(--colorWhite);
      background-color: var(--marketplaceColor);

      @media (--viewportSmall) {
        padding: 8px 20px;
      }
    }
  }
}

.landingHeroSearch {
  width: 100%;
  margin: 20px auto;
  @media (--viewportMedium) {
    max-width: 70%;
  }
  & .searchItemsContainer {
    border: none;
    height: auto;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 10px;
    border: solid 1px var(--colorWhite);
    background-color: var(--colorWhite);
    border-radius: 6px;
    & .keywordSearch {
      border: none;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 0px;
      order: 1;
      margin-bottom: 0px;
      width: calc(100% - 45px);

      & > svg {
        & path,
        & circle {
          stroke: var(--colorGrey100);
        }
      }

      & .field {
        width: calc(100% - 20px);

        & > input {
          padding: 10px 15px;
        }
      }
    }

    & .button {
      width: auto;
      height: auto;
      font-weight: 600;
      border-radius: 100px;
      padding: 0;
      width: 45px;
      height: 45px;
      order: 2;
      color: var(--colorWhite);
      background-color: var(--marketplaceColor);
      transform: rotate(270deg);

      & > svg {
        width: 20px;
        height: 20px;
        & path,
        & circle {
          stroke: var(--colorWhite);
        }
      }
      &:hover {
        background-color: var(--colorGrey700);
      }
    }
  }
}

.searchKeywords {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
  & > strong {
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 24px;
  }

  & > .pills {
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 24px;
    border-radius: 100px;
    cursor: pointer;
    position: relative;
    transition: all ease 0.5s;
    text-decoration: none !important;
    color: var(--colorGrey800);
    &:hover {
      color: var(--marketplaceColor);
      transition: all ease 0.5s;
    }
  }
}

.mobileButton {
  background: #e093a9;
  width: fit-content !important;
  height: fit-content;
  padding: 4px 24px;
  color: white;
}
