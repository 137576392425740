@import '../../../styles/customMediaQueries.css';

.contentWidth {
  composes: fixedWidthContainer from global;
}
:global(.slick-slider) {
  width: 100%;
}
:global(.slick-list) {
  margin: 0 -10px;

  @media (min-width: 1300px) {
    margin: 0 -15px;
  }
  & :global(.slick-track) {
    & :global(.slick-slide) {
      :global(.slick-slider) {
        width: 100%;
        & :global(.slick-list) {
          margin: 0px;
          & :global(.slick-slide) {
            padding: 0px;
          }
        }
      }
    }
  }
}
:global(.slick-track) {
}
:global(.slick-slide) {
  padding: 10px 10px;

  @media (min-width: 1300px) {
    padding: 15px 15px;
  }

  & img {
    max-width: 100%;
    /* position: absolute; */
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
:global(.slick-dots) {
  bottom: -40px;
  & > li {
    margin: 0;
    & > button {
      padding: 0;
      &::before {
        font-size: 8px;
      }
    }
  }
}
:global(.slick-arrow) {
  width: 40px;
  height: 40px;
  display: inline-flex !important;
  background-color: var(--colorWhite);
  align-items: center;
  justify-content: center;
  box-shadow: var(--boxShadowPopupLight);
  border-radius: 100px;
  z-index: 1;
  &:hover,
  &:focus {
    background-color: var(--marketplaceColor);
  }
}
:global(.slick-arrow.slick-prev) {
  left: 16px;
}
:global(.slick-arrow.slick-next) {
  right: 16px;
}
:global(.slick-arrow.slick-prev.slick-disabled) {
  background-color: var(--colorGrey100);
  opacity: 0;
}
:global(.slick-arrow.slick-next.slick-disabled) {
  background-color: var(--colorGrey100);
  opacity: 0;
}

:global(.slick-arrow.slick-prev::before) {
  content: ' ';
  display: inline-block;
  width: 10px;
  height: 16px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDkgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03LjQ5OTkgMTUuMjI1QzcuMzQxNTcgMTUuMjI1IDcuMTgzMjQgMTUuMTY2NyA3LjA1ODI0IDE1LjA0MTdMMS42MjQ5IDkuNjA4MzVDMC43NDE1NjkgOC43MjUwMiAwLjc0MTU2OSA3LjI3NTAxIDEuNjI0OSA2LjM5MTY4TDcuMDU4MjQgMC45NTgzNUM3LjI5OTkgMC43MTY2ODMgNy42OTk5IDAuNzE2NjgzIDcuOTQxNTcgMC45NTgzNUM4LjE4MzI0IDEuMjAwMDIgOC4xODMyNCAxLjYwMDAyIDcuOTQxNTcgMS44NDE2OEwyLjUwODI0IDcuMjc1MDJDMi4xMDgyNCA3LjY3NTAyIDIuMTA4MjQgOC4zMjUwMiAyLjUwODI0IDguNzI1MDJMNy45NDE1NyAxNC4xNTgzQzguMTgzMjQgMTQuNCA4LjE4MzI0IDE0LjggNy45NDE1NyAxNS4wNDE3QzcuODE2NTcgMTUuMTU4MyA3LjY1ODI0IDE1LjIyNSA3LjQ5OTkgMTUuMjI1WiIgZmlsbD0iIzFBMUUyNSIvPgo8L3N2Zz4K);
}
:global(.slick-arrow.slick-next::before) {
  content: ' ';
  display: inline-block;
  width: 10px;
  height: 16px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDkgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjUwMDEgMTUuMjI1QzEuNjU4NDMgMTUuMjI1IDEuODE2NzYgMTUuMTY2NyAxLjk0MTc2IDE1LjA0MTdMNy4zNzUxIDkuNjA4MzVDOC4yNTg0MyA4LjcyNTAyIDguMjU4NDMgNy4yNzUwMSA3LjM3NTEgNi4zOTE2OEwxLjk0MTc2IDAuOTU4MzVDMS43MDAxIDAuNzE2NjgzIDEuMzAwMSAwLjcxNjY4MyAxLjA1ODQzIDAuOTU4MzVDMC44MTY3NjMgMS4yMDAwMiAwLjgxNjc2MyAxLjYwMDAyIDEuMDU4NDMgMS44NDE2OEw2LjQ5MTc2IDcuMjc1MDJDNi44OTE3NiA3LjY3NTAyIDYuODkxNzYgOC4zMjUwMiA2LjQ5MTc2IDguNzI1MDJMMS4wNTg0MyAxNC4xNTgzQzAuODE2NzYzIDE0LjQgMC44MTY3NjMgMTQuOCAxLjA1ODQzIDE1LjA0MTdDMS4xODM0MyAxNS4xNTgzIDEuMzQxNzYgMTUuMjI1IDEuNTAwMSAxNS4yMjVaIiBmaWxsPSIjMUExRTI1Ii8+Cjwvc3ZnPgo=);
}
.popularSessions {
  width: 100%;
  display: block;
  overflow: hidden;
  @media (min-width: 1280px) {
    overflow: hidden;
    position: relative;
  }
  & .contentWidth {
    padding: 0px;
    display: block;
    & .featuredListingsContent {
    }
  }

  & .card {
    position: relative;
    padding-bottom: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 6px;
    overflow: hidden;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      content: '';
      background-image: linear-gradient(181deg, transparent, rgb(0 0 0 / 80%));
      height: 40%;
      z-index: 0;
    }
    & .cardDetails {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      padding: 20px;
      z-index: 1;
      & > h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 130%;
        letter-spacing: 0em;
        color: var(--colorWhite);
        text-align: center;
        padding: 0;
        margin: 0;
      }
      & .button {
        width: 100%;
        margin-top: 10px;
        transition: var(--transitionStyle);
      }
    }
  }
}
.sectionHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    flex-wrap: nowrap;
    margin-bottom: 32px;
  }
  & .leftSec {
    & h2 {
      font-weight: 600;
      letter-spacing: 0em;
      color: var(--colorGrey900);
      margin: 0px;
      padding: 0;
      font-size: 20px;
      line-height: 130%;
      @media (--viewportMedium) {
        font-size: 24px;
      }
      @media (--viewportLarge) {
        font-size: 28px;
      }
    }
    & p {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0em;
      color: var(--colorGrey600);
      margin: 5px 0px;
      padding: 0;
    }
  }
  & .rightSec {
    & > a {
      display: inline-block;
      color: var(--marketplaceColor);
      padding: 0px;
      transition: var(--transitionStyle);
      text-decoration: underline;
      &:hover {
        transition: var(--transitionStyle);
        color: var(--marketplaceColorLight);
        text-decoration: underline;
        & > svg {
          & > path {
            stroke: var(--marketplaceColorDark);
          }
        }
      }
      & > svg {
        margin-left: 12px;
        fill: none;
      }
    }
  }
}
.button {
  composes: whiteBtn from global;
}
.sectionPadding {
  composes: sectionPadding from global;
}

.howItWorks {
  background-color: #fff;
  @media (max-width: 767px) {
    padding-bottom: 50px;
  }
  & :global(.slick-dots) {
    bottom: -30px;
  }
  & .sectionHead {
    margin-bottom: 60px;
    & .leftSec {
      width: 100%;
      text-align: center;
    }
  }
  & .mobileHowItWorksSlider {
    @media (max-width: 580px) {
      text-align: center;
    }
  }
  & .sectionContent {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @media (--viewportMedium) {
      grid-template-columns: repeat(3, 1fr);
    }

    & .block {
      text-align: center;
      position: relative;
      background-color: var(--colorWhite);
      box-shadow: var(--boxShadow);
      padding: 24px;
      border-radius: var(--borderRadiusLarge);
      @media (--viewportMedium) {
        border-radius: 0;
        padding: 0 24px;
        background-color: transparent;
        box-shadow: none;
      }
      @media (--viewportMLarge) {
        padding: 0 48px;
      }
      @media (--viewportXLarge) {
        padding: 0 60px;
      }
      & .blockIcon {
        margin-bottom: 24px;
        padding: 12px;
        border: solid 1px var(--colorGrey100);
        background-color: var(--colorWhite);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
      }
      & h3 {
        font-weight: var(--fontWeightSemiBold);
        letter-spacing: 0em;
        color: var(--colorGrey900);
        margin: 0px 0 10px 0;
        padding: 0;
        font-size: 18px;
        line-height: 130%;
        @media (--viewportLarge) {
          font-size: 20px;
          margin: 0px 0 15px 0;
        }
      }
      & p {
        font-size: 14px;
        font-weight: var(--fontWeightRegular);
        line-height: 160%;
        letter-spacing: 0em;
        color: var(--colorGrey700);
        margin: 0px 0 10px 0;
        padding: 0;
        @media (--viewportLarge) {
          font-size: 16px;
          margin: 0px 0 15px 0;
        }
      }
    }
  }
}
.categorySection {
  background-color: var(--colorWhite);
  & .sectionHead {
    & .leftSec {
      width: 100%;
    }
  }
  & .sectionContent {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;
    @media (--viewportSmall) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (--viewportLarge) {
      grid-template-columns: repeat(4, 1fr);
    }
    & .block {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      position: relative;
      background-color: #e0ebfd;
      border: solid 1px #aad9f7;
      padding: 40px 20px;
      padding-bottom: 100%;
      overflow: hidden;
      cursor: pointer;
      border-radius: var(--borderRadiusLarge);
      transition: var(--transitionStyle);
      &:hover {
        transition: var(--transitionStyle);
        border-color: #aad9f7;
        background-color: var(--colorWhite);
      }
      &::after {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        z-index: 0;
        background-color: rgb(11 11 11 / 36%);
        height: 100%;
        width: 100%;
        content: '';
      }
      & > img {
        max-width: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 0;
      }
      & > h3 {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        font-size: 18px;
        font-weight: var(--fontWeightSemiBold);
        line-height: 110%;
        padding: 0;
        margin: 0;
        color: var(--colorWhite);
        @media (--viewportLarge) {
          font-size: 24px;
        }
      }
    }
  }
  & .mobileCategorySlider {
    & .block {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      position: relative;
      background-color: #e0ebfd;
      border: solid 1px #aad9f7;
      padding: 40px 20px;
      padding-bottom: 100%;
      overflow: hidden;
      cursor: pointer;
      border-radius: var(--borderRadiusLarge);
      transition: var(--transitionStyle);
      &:hover {
        transition: var(--transitionStyle);
        border-color: #aad9f7;
        background-color: var(--colorWhite);
      }
      &::after {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        z-index: 0;
        background-color: rgb(11 11 11 / 36%);
        height: 100%;
        width: 100%;
        content: '';
      }
      & > img {
        max-width: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 0;
      }
      & > h3 {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        font-size: 18px;
        font-weight: var(--fontWeightSemiBold);
        line-height: 110%;
        padding: 0;
        margin: 0;
        color: var(--colorWhite);
        @media (--viewportLarge) {
          font-size: 24px;
        }
      }
    }
  }
}
.instagramSection {
  & .sectionHead {
    & .leftSec {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        max-width: 100%;
        width: 80%;
      }
    }
  }
  & .sectionContent {
    display: flex;
    overflow-x: auto;
    @media (--viewportMedium) {
      overflow: visible;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 24px;
    }
    & > a {
      position: relative;
      border-radius: var(--borderRadiusLarge);
      overflow: hidden;
      box-shadow: var(--boxShadowPopupLight);
      min-width: 250px;
      width: calc(100% / 4);

      &:not(:last-child) {
        margin-right: 24px;
      }
      @media (--viewportMedium) {
        min-width: auto;
        margin-right: 0px;
        width: 100%;
      }
      & > img {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }
  }
}

.sliderItem {
  position: relative;
  height: 100%;
  cursor: pointer;
  padding-bottom: 80%;
  outline: none;
  transition: var(--transitionStyleLarge);
  overflow: hidden;
  &:hover {
    transition: var(--transitionStyleLarge);
    & img {
      transform: scale(1.05);
      transition: var(--transitionStyleLarge);
    }
  }
  &::after {
    transition: var(--transitionStyleLarge);
    content: '';
    width: 100%;
    height: 100%;
    background-color: #0b0b0b0d;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    display: block;
    border-radius: 10px;
  }
  & img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1);
    transition: var(--transitionStyleLarge);
  }
  & .sliderContent {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    padding: 30px;
    & > h1 {
      font-size: 20px !important;
      line-height: 110%;
      margin: 0;
      color: var(--colorWhite);
      font-weight: var(--fontWeightMedium);
      @media (--viewportMedium) {
        font-size: 24px;
      }
    }
  }
}
.aboutSlider {
  width: 100%;
  position: relative;
  & .sectionHead {
    & .leftSec {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  & :global(.slick-arrow) {
    position: absolute;
    z-index: 1;
    background-color: var(--colorWhite);
    width: 60px;
    height: 60px;
    border-radius: 100px;
    top: calc(100% / 2);
  }
  & :global(.slick-prev) {
    left: -30px;
  }
  & :global(.slick-prev::before) {
    content: ' ';
    background-image: url(data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyBmaWxsPSIjMDAwMDAwIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMDAgMTAwIiB4bWw6c3BhY2U9InByZXNlcnZlIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMi44MDAwMDAwMDAwMDAwMDAzIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPGc+IDxwYXRoIGQ9Ik0zMy45MzQsNTQuNDU4bDMwLjgyMiwyNy45MzhjMC4zODMsMC4zNDgsMC44NjQsMC41MTksMS4zNDQsMC41MTljMC41NDUsMCwxLjA4Ny0wLjIyMiwxLjQ4Mi0wLjY1NyBjMC43NDEtMC44MTgsMC42OC0yLjA4My0wLjEzOS0yLjgyNEwzNy44MDEsNTIuNTY0TDY0LjY3LDIyLjkyMWMwLjc0Mi0wLjgxOCwwLjY4LTIuMDgzLTAuMTM5LTIuODI0IGMtMC44MTctMC43NDItMi4wODItMC42NzktMi44MjQsMC4xMzlMMzMuNzY4LDUxLjA1OWMtMC40MzksMC40ODUtMC41OSwxLjEyNi0wLjQ3NSwxLjcyMyBDMzMuMjM0LDUzLjM5LDMzLjQ0Niw1NC4wMTcsMzMuOTM0LDU0LjQ1OHoiLz4gPC9nPiA8L2c+Cg08L3N2Zz4=);
    display: inline-block;
    margin-left: 20px;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 24px;
    height: 24px;
  }

  & :global(.slick-next) {
    right: -30px;
  }
  & :global(.slick-next::before) {
    content: ' ';
    background-image: url(data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyBmaWxsPSIjMDAwMDAwIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMDAgMTAwIiB4bWw6c3BhY2U9InByZXNlcnZlIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMi44MDAwMDAwMDAwMDAwMDAzIiB0cmFuc2Zvcm09InJvdGF0ZSgxODApIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPGc+IDxwYXRoIGQ9Ik0zMy45MzQsNTQuNDU4bDMwLjgyMiwyNy45MzhjMC4zODMsMC4zNDgsMC44NjQsMC41MTksMS4zNDQsMC41MTljMC41NDUsMCwxLjA4Ny0wLjIyMiwxLjQ4Mi0wLjY1NyBjMC43NDEtMC44MTgsMC42OC0yLjA4My0wLjEzOS0yLjgyNEwzNy44MDEsNTIuNTY0TDY0LjY3LDIyLjkyMWMwLjc0Mi0wLjgxOCwwLjY4LTIuMDgzLTAuMTM5LTIuODI0IGMtMC44MTctMC43NDItMi4wODItMC42NzktMi44MjQsMC4xMzlMMzMuNzY4LDUxLjA1OWMtMC40MzksMC40ODUtMC41OSwxLjEyNi0wLjQ3NSwxLjcyMyBDMzMuMjM0LDUzLjM5LDMzLjQ0Niw1NC4wMTcsMzMuOTM0LDU0LjQ1OHoiLz4gPC9nPiA8L2c+Cg08L3N2Zz4=);
    display: inline-block;
    margin-right: 20px;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 24px;
    height: 24px;
  }
  & :global(.slick-slide) {
    height: auto;
    padding: 50px 0;
    transition: var(--transitionStyleLarge);
  }
  & :global(.slick-slide.slick-active.slick-center.slick-current) {
    padding: 0;
    transition: var(--transitionStyleLarge);
    transform: scale(1.02);
    z-index: 2;
    overflow: hidden;
    & .sliderItem {
      border-radius: 6px;
    }
  }
  & :global(.slick-track) {
    display: flex;
    align-items: stretch;
    padding: 30px 0;
  }
  & :global(.slick-slide > div) {
    height: 100%;
  }
  /* slide when not active/center*/
  &
    :global(.slick-slide[aria-hidden='true']:not(.slick-cloned)
      ~ .slick-cloned[aria-hidden='true']) {
    transition: var(--transitionStyleLarge);
  }

  /* slide when active/center */
  &
    :global(.slick-slide[aria-hidden='true']:not([tabindex='-1'])
      + .slick-cloned[aria-hidden='true']) {
    transition: var(--transitionStyleLarge);
  }
}
