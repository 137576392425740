@import '../../../styles/marketplaceDefaults.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  /* animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative; */
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.heroContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: var(--colorWhite);
  background: linear-gradient(
    180deg,
    rgba(224, 147, 169, 0.8071603641456583) 0%,
    rgba(255, 255, 255, 1) 61%
  );

  @media (--viewportMedium) {
    flex-direction: row;
  }

  @media (max-width: 767px) {
    text-align: center;
    justify-content: center;
  }

  @media (min-width: 1500px) {
    margin: 0 auto;
    width: 100%;
  }
}

.heroLeftContent {
  width: 100%;
  text-align: center;
  @media (--viewportLarge) {
    width: 70%;
  }
  @media (--viewportLargeWithPaddings) {
    width: 60%;
  }

  @media (--viewportXLarge) {
    width: 50%;
  }
  & .heroInnerContent {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 24px;
    @media (--viewportMedium) {
      padding: 80px 24px;
    }
  }
}
.heroRightContent {
  width: 100%;
  background: #f2f4f7;
  overflow: hidden;
  z-index: 0;
  position: relative;

  @media (--viewportMedium) {
    width: 50%;
  }

  & > img {
    max-width: 100%;
  }

  & :global(.react-multi-carousel-dot-list) {
    bottom: 10px;
  }

  & :global(.react-multi-carousel-dot button) {
    border-color: var(--colorWhite);
    background-color: var(--colorWhite);
    border-width: 1px;
    opacity: 0.5;
  }

  & :global(.react-multi-carousel-dot.react-multi-carousel-dot--active button) {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
    opacity: 1;
  }

  & :global(.alice-carousel > div > div) {
    @media (min-width: 320px) and (max-width: 425px) {
      /* height: 383px !important; */
    }
  }
}

.filterSearchBox {
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}
.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: #101828;
  font-weight: var(--fontWeightSemiBold);
  font-size: 30px;
  composes: animation;
  animation-delay: 0.5s;
  line-height: 130%;
  margin-bottom: 10px;
  margin-top: 0;

  @media (--viewportMedium) {
    font-size: 36px;
    margin-bottom: 20px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 40px;
  }

  @media (min-width: 1600px) {
    font-size: 48px;
  }
}

.heroSubTitle {
  composes: marketplaceH4FontStyles from global;

  color: #475467;
  margin: 0 0 24px 0;
  font-size: 15px;
  line-height: 150%;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportSmall) {
    margin: 0 0 32px 0;
    font-size: 16px;
  }

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton {
  composes: marketplaceButtonStyles from global;
  color: #ffffff;
  composes: animation;
  line-height: 100%;
  animation-delay: 0.8s;

  @media (--viewportSmall) {
    max-width: 190px;
    line-height: 32px;
    width: 100%;
  }
}

.joinUsbox {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }

  & > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--colorGrey700);
    display: inline-block;
    @media (--viewportMedium) {
      font-size: 18px;
    }
  }

  & > a {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    text-decoration: none;
    color: var(--marketplaceColorDark);
    transition: all ease 0.5s;
    @media (--viewportMedium) {
      font-size: 18px;
    }
    &:hover {
      transition: all ease 0.5s;
      color: #000;
    }

    & > svg {
      margin-left: 7px;
      width: 20px;
    }
  }
}
